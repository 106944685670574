@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@400;600&family=Inter:wght@400;600&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* :root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #EEEEEE;
    --foreground: #313033;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
} */

.remove-arrows::-webkit-outer-spin-button,
.remove-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrows {
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
}

.payverde{
  background: #FCFEFEF2;
  box-shadow: 0px 4px 6px 0px #00000014;
}

.payverde1{
  background: linear-gradient(180deg, #FCFEFE 39.9%, #FBFBFB 79.5%);
  border: linear-gradient(90deg, #00B9F3 0%, #4169E1 70.4%, #3A71E3 100%);
  box-shadow: 0px 10px 50px 20px #4169E126;
}

.email-gradient {
  background: linear-gradient(90deg, #00B9F3 0%, #4169E1 70.4%, #3A71E3 100%);
  box-shadow: 0px 10px 50px 20px #4169E126;
  border-radius: 20px;
  overflow:hidden;
}

.email-gradient2 {
  background: linear-gradient(90deg, #4169E1 0%, #00B9F3 50%, #4169E1 100%);
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.email-gradient3 {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;
}

.email-gradient32 {
  border-radius: 35px;
  overflow: hidden;
}
.email-gradient33 {
  border-radius: 29px;
  overflow: hidden;
}

.text-custom-gradient {
 background: linear-gradient(90deg, #00B9F3 0%, #4169E1 70.4%);
 -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.text-custom-gradient2 {
  text: rgba(0, 0, 8, 0.7);
}

.bg-section1 {
  background: linear-gradient(180deg, #FCFEFE 39.9%, #FBFBFB 79.5%);
}

.bg-section4 {
  background: var(--Neutral-100, #FFFFFF);
}

.bg-card-section2 {
  background: linear-gradient(180deg, #FCFEFE 39.9%, #FBFBFB 79.5%);
}

.card-payverde {
  background: linear-gradient(180deg, #FCFEFE 0%, rgba(254, 254, 254, 0.9) 23.9%, rgba(254, 254, 254, 0.9) 100%);
  border: 0.8px solid rgba(236, 236, 236, 1)
}

.card-payverde2 {
  background: linear-gradient(180deg, #FCFEFE 0%, rgba(254, 254, 254, 0.9) 31.4%, rgba(254, 254, 254, 0.9) 68%);
  border: 0.8px solid #ECECEC
}

.card-gradient {
  background: linear-gradient(90deg, rgba(65, 105, 225, 0.9) 29.9%, rgba(0, 185, 243, 0.9) 70.4%);
  box-shadow: 0px 27px 8px 0px #00000005;
}

.card-gradient2 {
  background: #F9FAFB;
  box-shadow: 0px 2px 4px 0px #0000000A;
}
